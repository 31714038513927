import { Button } from "block-system/brickz/components/ui/Button";
import type Stripe from "stripe";
import { animateTransition } from "block-system/blocks/__shared__/transition";
import { CircleAlert, CircleCheck, CircleX } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/block-system/brickz/components/ui/dialog";

const modalTitles: Record<Stripe.Checkout.Session.Status, string> = {
  complete: "Payment successful",
  expired: "Payment expired",
  open: "Payment failed or was cancelled",
};

export function StripeCheckoutStatusModal({
  checkoutStatus,
  onClosed,
}: {
  checkoutStatus: Stripe.Checkout.Session.Status;
  onClosed: () => void;
}) {
  return (
    <Dialog
      defaultOpen={true}
      modal={true}
      onOpenChange={() => {
        animateTransition(() => {
          onClosed();
        });
      }}
    >
      <DialogContent>
        {checkoutStatus === "complete" ? (
          <StripeCheckoutSuccess
            onClose={() => {
              animateTransition(() => {
                onClosed();
              });
            }}
          />
        ) : checkoutStatus === "expired" ? (
          <StripeCheckoutExpired
            onClose={() => {
              animateTransition(() => {
                onClosed();
              });
            }}
          />
        ) : (
          <StripeCheckoutFailed
            onClose={() => {
              animateTransition(() => {
                onClosed();
              });
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

export function StripeCheckoutSuccess({ onClose }: { onClose: () => void }) {
  return (
    <div className={"mt-4 flex flex-col items-center"}>
      <CircleCheck size={40} className="text-green-600" />
      <DialogHeader>
        <DialogTitle className="mb-8 mt-3 p-0 text-2xl">
          {modalTitles.complete}
        </DialogTitle>
      </DialogHeader>
      <Button
        className={"w-[max-content]"}
        type="button"
        onClick={() => {
          onClose();
        }}
      >
        Close
      </Button>
    </div>
  );
}

export function StripeCheckoutFailed({ onClose }: { onClose: () => void }) {
  return (
    <div className={"mt-4 flex flex-col items-center"}>
      <CircleX size={40} className="text-orange-600" />
      <DialogHeader>
        <DialogTitle className="mb-8 mt-3 p-0 text-2xl">
          {modalTitles.open}
        </DialogTitle>
      </DialogHeader>
      <Button
        className={"w-[max-content]"}
        type="button"
        onClick={() => {
          onClose();
        }}
      >
        Close
      </Button>
    </div>
  );
}

export function StripeCheckoutExpired({ onClose }: { onClose: () => void }) {
  return (
    <div className={"mt-4 flex flex-col items-center"}>
      <CircleAlert size={40} className="text-yellow-600" />
      <DialogHeader>
        <DialogTitle className="mb-8 mt-3 p-0 text-2xl">
          {modalTitles.expired}
        </DialogTitle>
      </DialogHeader>
      <Button
        className={"w-[max-content]"}
        type="button"
        onClick={() => {
          onClose();
        }}
      >
        Close
      </Button>
    </div>
  );
}
