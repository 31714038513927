import Link from "next/link";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
  NavigationMenuViewport,
} from "./navigation-menu";
import { cn } from "../../lib/utils";
import { Button } from "./Button";
import { ChevronsUpDown, Menu, X } from "lucide-react";
import { Avatar, AvatarFallback, AvatarImage } from "./avatar";
import * as NavigationMenuPrimitive from "@radix-ui/react-navigation-menu";
import { truncate } from "lodash";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./dropdown-menu";

type NavItem = {
  name: string;
  href: string;
  current: boolean;
  target?: "_self" | "_blank" | "_parent" | "_top";
};

export type ResponsiveNavProps = {
  navItems: NavItem[];
  profileMenuItems: {
    name: string;
    onClick: () => void;
  }[];
  user: {
    name?: string | null;
    email?: string;
    avatarUrl?: string;
    avatarAltText?: string | null;
  };
  interfaceName?: string;
  includeLogo: boolean;
  logo?: {
    name: string;
    url: string;
    showLogoOnLoginPage: boolean;
    height: number;
  } | null;
  logoHref?: string;
  logoAltText?: string;
};

export function ResponsiveNav({
  interfaceName,
  navItems,
  logo,
  logoHref,
  logoAltText,
  includeLogo,
  user,
  profileMenuItems,
}: ResponsiveNavProps) {
  if (user && !user.name && !user.email && !navItems.length && !includeLogo) {
    return null;
  }

  return (
    <NavigationMenu
      className={cn(
        "grid grid-cols-[auto_1fr_auto] max-md:grid-cols-[auto_auto]",
        "max-md:justify-between",
        "w-screen",
        "bg-card",
        "min-h-14 w-full",
        "gap-8",
        "px-4 py-1",
        "border-b border-border"
      )}
      data-testid="builder-page-nav"
      viewportClassName="left-[unset] right-0"
    >
      <Logo
        logo={logo}
        logoHref={logoHref}
        logoAltText={logoAltText}
        interfaceName={interfaceName}
        includeLogo={includeLogo}
      />

      <div className="overflow-x-auto max-md:hidden">
        <NavigationMenuList aria-orientation="horizontal">
          {navItems.map((item) => (
            <NavigationMenuItem key={item.name}>
              <Link href={item.href} legacyBehavior passHref>
                <NavigationMenuLink
                  className={navigationMenuTriggerStyle({
                    class: [
                      "h-9",
                      "px-4 py-2",
                      "select-none bg-transparent",
                      "text-card-foreground",
                      "data-[active=true]:bg-accent data-[active='true']:text-accent-foreground",
                      "hover:bg-secondary hover:text-secondary-foreground",
                    ],
                  })}
                  target={item.target}
                  aria-current={item.current ? "page" : undefined}
                  data-active={item.current ? "true" : undefined}
                >
                  {item.name}
                </NavigationMenuLink>
              </Link>
            </NavigationMenuItem>
          ))}
        </NavigationMenuList>
      </div>

      <div className="flex items-center gap-6">
        {user.email ? (
          <ProfileMenu user={user} menuItems={profileMenuItems} />
        ) : null}
        <MobileMenu
          navItems={navItems}
          menuLabel="Menu"
          className="md:hidden"
        />
      </div>
    </NavigationMenu>
  );
}

function Logo({
  logo,
  includeLogo,
  interfaceName,
  logoHref = "/",
  logoAltText = "",
}: Pick<
  ResponsiveNavProps,
  "logo" | "logoHref" | "logoAltText" | "includeLogo" | "interfaceName"
>) {
  if (!includeLogo && !interfaceName) return null;

  const showInterfaceName = !!interfaceName && !includeLogo;

  const { name = "", url } = logo ?? {};
  const size = logo?.height ?? 32;

  return (
    <Link
      href={logoHref}
      title={logoAltText}
      className="flex items-center gap-2"
    >
      {includeLogo ? (
        /* eslint-disable-next-line @next/next/no-img-element */
        <img
          src={url ? url : "/icon-interfaces.svg"}
          alt={logoAltText ?? name}
          className="object-contain"
          style={{ height: size, minWidth: size }}
        />
      ) : null}
      {showInterfaceName ? (
        <span className="text-nowrap text-lg font-semibold text-card-foreground">
          {interfaceName}
        </span>
      ) : null}
    </Link>
  );
}

function MobileMenu({
  navItems,
  menuLabel,
  className,
}: {
  navItems: ResponsiveNavProps["navItems"];
  menuLabel?: string;
  className?: string;
}) {
  /**
   * We are using `asChild` here to make the below component a `div` instead of a `nav`
   * to avoid having a `nav` inside a `nav` from the parent `ResponsiveNav` component .
   * Unfortunately, we can't use `NavigationMenu` from Shadcn here because adding the `asChild` props
   * throws an error about the component having multiple children. This is due to the Shadcn
   * implementation of the `NavigationMenu` component.
   * https://github.com/radix-ui/primitives/issues/1979#issuecomment-2495377245
   * So we use `NavigationMenu` imported from Radix UI directly.
   */
  return (
    <NavigationMenuPrimitive.Root className={className} asChild>
      <div>
        <NavigationMenuList>
          <NavigationMenuItem>
            <NavigationMenuTrigger
              className={cn(
                "group",
                "flex gap-2.5",
                "h-10 max-sm:w-10",
                "px-4 py-2",
                "max-sm:p-0",
                "bg-transparent",
                "hover:bg-transparent",
                "data-[state=open]:bg-transparent",
                "focus:bg-transparent focus:text-card-foreground",
                "focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0",
                "border border-solid border-border",
                "text-card-foreground hover:text-card-foreground"
              )}
              hideIcon
            >
              <div
                className={cn(
                  "flex items-center justify-between max-sm:justify-center",
                  "h-10 max-sm:w-10"
                )}
              >
                <div className={"flex items-center"}>
                  <Menu
                    size={16}
                    className="group-data-[state=open]:hidden"
                    aria-hidden={true}
                  />
                  <X
                    size={16}
                    className="group-data-[state=closed]:hidden"
                    aria-hidden={true}
                  />
                  {menuLabel ? (
                    <span className="ml-2 max-sm:hidden">Menu</span>
                  ) : null}
                </div>
              </div>
            </NavigationMenuTrigger>
            <NavigationMenuContent className="w-[280px] min-w-[280px] p-1.5">
              <NavigationMenuList
                className="flex flex-col"
                aria-orientation="vertical"
              >
                {navItems.map((item) => (
                  <NavigationMenuItem
                    key={item.name}
                    className="w-full rounded-md"
                  >
                    <Link href={item.href} legacyBehavior passHref>
                      <NavigationMenuLink
                        className={navigationMenuTriggerStyle({
                          class: [
                            "h-8",
                            "px-2 py-1.5",
                            "select-none",
                            "items-start",
                            "bg-transparent text-popover-foreground",
                            "data-[active=true]:bg-accent data-[active=true]:text-accent-foreground",
                            "hover:bg-secondary hover:text-secondary-foreground",
                            "tex-left",
                          ],
                        })}
                        aria-current={item.current ? "page" : undefined}
                        data-active={item.current ? "true" : undefined}
                        target={item.target}
                      >
                        {item.name}
                      </NavigationMenuLink>
                    </Link>
                  </NavigationMenuItem>
                ))}
              </NavigationMenuList>
            </NavigationMenuContent>
          </NavigationMenuItem>
        </NavigationMenuList>
        {/* The right-4 is to align the menu with the menu trigger */}
        <NavigationMenuViewport containerClassName="left-[unset] right-4" />
      </div>
    </NavigationMenuPrimitive.Root>
  );
}

function ProfileMenu({
  user,
  menuItems,
}: {
  user: ResponsiveNavProps["user"];
  menuItems: ResponsiveNavProps["profileMenuItems"];
}) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="outline"
          className={cn(
            "bg-transparent",
            "text-card-foreground",
            "hover:bg-transparent hover:text-card-foreground",
            "md:border md:border-solid md:border-border",
            "max-md:h-8 max-md:min-h-8 max-md:w-8",
            "px-3 max-md:p-0",
            "focus-visible:outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
          )}
        >
          <div className="flex items-center gap-2">
            <div className={"flex items-center gap-2"}>
              <Avatar className={cn("h-8 w-8 md:h-6 md:w-6")}>
                <AvatarImage src={user.avatarUrl} />
                <AvatarFallback>
                  <FallbackAvatarElement />
                </AvatarFallback>
              </Avatar>
              <span className="max-md:hidden">
                {/* The length 30 is arbitrary */}
                {truncate(user.name ?? "", { length: 30 })}
              </span>
            </div>
            <ChevronsUpDown size={16} className="opacity-60 max-md:hidden" />
          </div>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-[280px] gap-2 p-1.5" align="end">
        <div className="flex items-center gap-2 p-2">
          <Avatar className={cn("h-8 w-8")}>
            <AvatarImage src={user.avatarUrl} />
            <AvatarFallback>
              <FallbackAvatarElement />
            </AvatarFallback>
          </Avatar>
          <div className="flex flex-col gap-1">
            <span className="text-[14px] font-medium leading-[14px]">
              {/* The length 30 is arbitrary */}
              {truncate(user.name ?? "", { length: 30 })}
            </span>
            <span className="text-xs text-muted-foreground">
              {/* The length 30 is arbitrary */}
              {truncate(user.email ?? "", { length: 30 })}
            </span>
          </div>
        </div>
        <DropdownMenuGroup>
          {menuItems.map((item) => (
            <DropdownMenuItem
              key={item.name}
              onSelect={item.onClick}
              className="cursor-pointer"
            >
              {item.name}
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function FallbackAvatarElement() {
  return (
    <div className="h-8 w-8 rounded-full bg-gradient-to-r from-orange-500 to-fuchsia-500" />
  );
}
